import {
  ABOUT_SLUG,
  CONTACT_SLUG,
  LEGAL_SLUG,
  PAGES_SLUG,
  FUTUREPROOF_URL,
} from "../core/urls";
import { LIGHT } from "../core/constants";
import React from "react";

import Image from "./Image";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import Link from "next/link";
import LottieOnEntered from "./LottieOnEntered";
import NewsletterSignup from "./NewsletterSignup";
import Text from "./Text";
import animData from "../assets/footer.json";
import DrapersLogo from "../assets/drapers-logo.svg";
import BFALogo from "../assets/BFA-logo.svg";
import ReLondonLogo from "../assets/relondon-logo.svg";
import Textiles230Logo from "../assets/textiles-230-logo.svg";
import UKFTLogo from "../assets/UKFT-logo.svg";
import FutureproofLogo from "../assets/futureproof-logo.svg";
import BlueEarthSummitLogo from "../assets/blue-earth-summit-logo.png";
import LivingWageLogo from "../assets/living-wage-logo.svg";

const FooterLink = ({ text, href }) => {
  return (
    <Box mx="-5px">
      <Link href={href} passHref>
        <Box
          as="a"
          color="white"
          transition="color 200ms"
          hover="color: #FFEE99"
          height="100%"
          py={["8px", null, 10, null]}
          px="5px"
        >
          <Text
            display="inline-flex"
            color="inherit"
            fontSize={14}
            lineHeight="28px"
          >
            {text}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};

const FooterExternalLink = ({ text, href, firstOfType, lastOfType }) => {
  return (
    <Box color="white" transition="color 200ms" hover="color: #FFEE99">
      <a href={href} target="_blank">
        <Box py="6px" pl={firstOfType ? 0 : 11} pr={lastOfType ? 0 : 11}>
          <Text color="inherit" fontSize={14}>
            {text}
          </Text>
        </Box>
      </a>
    </Box>
  );
};

const FooterSection = ({ title, children }) => {
  return (
    <Box>
      <Text
        color="white"
        fontSize={14}
        uppercase
        fontWeight={700}
        lineHeight={1}
        height={20}
        display={[title === "" ? "none" : "block", "block", null, null]}
      >
        {title}
      </Text>
      <Box mt={[title === "" ? 0 : 10, 20, null, null]}>{children}</Box>
    </Box>
  );
};

function Footer({ data }) {
  const aboutHref = `/${ABOUT_SLUG}`;
  const contactHref = `/${CONTACT_SLUG}`;
  return (
    <Box bg="black">
      <BoxWithGutter
        pt={[61, null, 96, null]}
        pb={[35, null, 35, null]}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box display="flex" justifyContent="space-between" pb={52}>
          <Box
            display="grid"
            gridTemplateColumns={[
              "1fr",
              "1fr 1fr",
              "auto auto auto auto",
              null,
            ]}
            maxWidth={1130}
            gridRowColumns="auto"
            gridColumnGap="2.38vw"
            gridRowGap={[30, null, 50, null]}
            flex="1"
          >
            <FooterSection title="Info">
              <FooterLink text="Contact us" href={contactHref} />
              <FooterLink text="About" href={aboutHref} />
              {data.globalLegalPages.map((page) => {
                const legalPageHref = `/${LEGAL_SLUG}/${page.slug}`;
                return (
                  <FooterLink
                    key={page.slug}
                    text={page.title}
                    href={legalPageHref}
                  />
                );
              })}
            </FooterSection>
            {data.legalSections.map((section) => {
              return (
                <FooterSection key={section.slug} title={section.title}>
                  {section.children.map((childItem) => {
                    const childHref = `/${PAGES_SLUG}/${section.slug}/${childItem.slug}`;
                    return (
                      <FooterLink
                        key={childItem.slug}
                        text={childItem.title}
                        href={childHref}
                      />
                    );
                  })}
                </FooterSection>
              );
            })}
          </Box>
          <LottieOnEntered
            src={animData}
            threshold={0.5}
            display={["none", "block", null, null]}
          />
        </Box>

        <Box py={[null, null, "6px", null]}>
          <div id="footer-signup"></div>
          <Box pb={38}>
            <Text
              color="white"
              fontSize={14}
              lineHeight="24px"
              maxWidth={350}
              pb={18}
            >
              <b>Sign up to Reskinned </b>
              to find out more about what we do and be the first to find out
              when drops are happening from the brands you love.
            </Text>
            <Box maxWidth={300}>
              <NewsletterSignup theme={LIGHT} />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          gap="20px"
          mb={[42, null, 60, null]}
          maxWidth={[350, "100%", null, null]}
          alignItems="center"
        >
          <LivingWageLogo />
          <DrapersLogo />
          <Textiles230Logo />
          <BFALogo />
          <ReLondonLogo />
          <UKFTLogo />
          <a href={FUTUREPROOF_URL} target="_blank">
            <FutureproofLogo />
          </a>
          <Box position="relative" height={40} width={156} my="6px">
            <Image
              src={BlueEarthSummitLogo.src}
              alt="Blue Earth Summit Logo"
              height="100%"
              width="100%"
              position="absolute"
            />
          </Box>
        </Box>

        <Box
          color="white"
          display="flex"
          mb={[30, null, 0, null]}
          justifyContent="space-between"
          flexDirection={["column", null, "row-reverse", null]}
        >
          <Box display="flex" mb={[15, null, 0, null]}>
            {data.socialLinks.map((item, index) => {
              const firstOfType = index === 0;
              const lastOfType = index === data.socialLinks.length - 1;
              return (
                <FooterExternalLink
                  key={item.value.name}
                  text={item.value.name.toUpperCase()}
                  href={item.value.link}
                  firstOfType={firstOfType}
                  lastOfType={lastOfType}
                />
              );
            })}
          </Box>
          <Text color="white" fontSize={14} lineHeight="28px" uppercase>
            ©Reskinned {new Date().getFullYear()}
          </Text>
        </Box>
      </BoxWithGutter>
    </Box>
  );
}

export default Footer;
