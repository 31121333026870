import { CONDITION_FILTER_TYPE } from "./constants";

export const formatPrice = (amount) => {
  let number;
  const float = parseFloat(amount);
  if (Number.isInteger(float)) {
    number = float.toString();
  } else {
    number = float.toFixed(2);
  }

  return `£${number}`;
};

export function getAvailableFiltersOfType(availableFilters, type) {
  return availableFilters.find((filter) => {
    return filter.type === type;
  });
}

export function getConditionFiltersInOrder(availableFilters) {
  const conditionFilter = getAvailableFiltersOfType(
    availableFilters,
    CONDITION_FILTER_TYPE
  );
  const brandNew =
    typeof conditionFilter === "undefined"
      ? undefined
      : conditionFilter.options.find((option) => option.value === "brand_new");
  const asNew =
    typeof conditionFilter === "undefined"
      ? undefined
      : conditionFilter.options.find((option) => option.value === "as_new");
  const preLoved =
    typeof conditionFilter === "undefined"
      ? undefined
      : conditionFilter.options.find((option) => option.value === "pre_loved");

  return [brandNew, asNew, preLoved];
}
