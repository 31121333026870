import Box from "./Box";
import Link from "next/link";
import React from "react";
import Text from "./Text";

function DropdownChildItem({ item, color }) {
  const href = `/${item.slug}`;
  return (
    <Link href={href} passHref>
      <Box as="a" width="100%" transition="opacity 200ms" hover="opacity: 0.5">
        <Text
          color={color}
          lineHeight="32px"
          fontFamily="ibmPlexMono"
          letterSpacing="2px"
          fontWeight={500}
          fontSize={14}
          uppercase
        >
          {item.name}
        </Text>
      </Box>
    </Link>
  );
}

function DropdownMenuItems({ item, color }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      flex={1}
      maxWidth={280}
    >
      {item.slug && (
        <DropdownChildItem
          item={{ name: `All ${item.name}`, slug: item.slug }}
          color={color}
        />
      )}
      {item.children.map((childItem) => {
        return (
          <DropdownChildItem
            key={childItem.slug}
            item={childItem}
            color={color}
          />
        );
      })}
    </Box>
  );
}

export default DropdownMenuItems;
