import React, { useRef } from "react";

import Box from "./Box";
import Link from "next/link";
import PropTypes from "prop-types";
import SmallCaps from "./SmallCaps";
import { useHovered } from "../core/hooks";

function NavItemLink({ item, firstOfType, color }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);
  const href = `/${item.slug}`;
  return (
    <Box ref={ref} height="calc(100% + 1px)" mb="-1px" cursor="pointer">
      <Link href={href}>
        <Box
          as="a"
          pl={firstOfType ? 50 : 50}
          pr={50}
          mr={-29}
          ml={firstOfType ? 0 : -29}
          height="100%"
          display="flex"
          alignItems="center"
          transition="opacity 200ms"
          opacity={hovered ? 0.5 : 1}
          flexDirection="column"
          justifyContent="center"
        >
          <SmallCaps color={color}>{item.name}</SmallCaps>
          <Box
            height="1px"
            width="100%"
            mt="1px"
            transition="opacity 200ms"
            borderBottom="1px solid"
            borderColor={color}
            opacity={hovered ? 1 : 0}
          />
        </Box>
      </Link>
    </Box>
  );
}

export default NavItemLink;

NavItemLink.propTypes = {
  item: PropTypes.object.isRequired,
  firstOfType: PropTypes.bool,
  color: PropTypes.string,
};
