import Box from "./Box";
import Button from "./Button";
import Link from "next/link";
import { PRODUCT_SLUG } from "../core/urls";
import React from "react";
import SquareImage from "./SquareImage";
import Text from "./Text";
import { formatPrice } from "../core/utils";
import { useCartContext } from "./CartContext";

function LineItem({ lineItem }) {
  const {
    cart: { id: cartId },
    removeFromCart,
    removeFromCartLoading,
  } = useCartContext();

  const { product } = lineItem.merchandise;
  const href = `/${PRODUCT_SLUG}/${product.handle}`;

  return (
    <Box
      my={[10, null, 20, null]}
      display="grid"
      gridTemplateColumns={["100px 1fr", null, "120px 1fr", null]}
      gridColumnGap="9px"
    >
      <Link href={href} passHref>
        <a>
          {product.featuredImage ? (
            <SquareImage
              src={product.featuredImage.src}
              alt={product.title}
              sizes="(max-width: 960px) 100px, 120px"
            />
          ) : (
            <Box bg="lightGrey" height={0} pb="100%" />
          )}
        </a>
      </Link>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Text
            fontSize={[12, null, 14, null]}
            fontWeight={700}
            uppercase
            lineHeight={1}
          >
            {product.brand}
          </Text>
          <Text
            fontSize={[12, null, 14, null]}
            fontWeight={700}
            uppercase
            lineHeight={1}
          >
            {formatPrice(lineItem.estimatedCost.totalAmount.amount)}
          </Text>
        </Box>
        <Box
          display={["block", null, "flex", null]}
          justifyContent="space-between"
          mt={["10px", null, "5px", null]}
        >
          <Link href={href} passHref>
            <Box as="a" transition="opacity 200ms" hover="opacity: 0.5">
              <Text fontSize={[12, 12, 14, 14]} uppercase lineHeight={1}>
                {product.title}
              </Text>
            </Box>
          </Link>
          <Button
            opacity={0.2}
            transition="opacity 200ms"
            hover="opacity: 1"
            disabled={removeFromCartLoading}
            onClick={() => {
              removeFromCart({
                variables: {
                  cartId,
                  lineIds: [lineItem.id],
                },
              });
            }}
          >
            <Text
              fontSize={[12, null, 14, null]}
              uppercase
              lineHeight={1}
              letterSpacing="2px"
              fontWeight={500}
              mt={["8px", null, 0, null]}
            >
              Remove
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default LineItem;
