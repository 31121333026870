export const TAKEBACK_SLUG = "takeback";
export const PRODUCT_SLUG = "product";
export const PAGES_SLUG = "pages";
export const ABOUT_SLUG = "about";
export const BRANDS_SLUG = "brand-partnerships";
export const DELIVERY_SLUG = "pages/resale/delivery-policy";
export const BLOG_SLUG = "the-re-edit-blog";
export const CONTACT_SLUG = "contact-us";
export const LEGAL_SLUG = "legal";
export const MENSWEAR_SLUG = "menswear";
export const WOMENSWEAR_SLUG = "womenswear";
export const KIDS_SLUG = "kids";
export const LATEST_DROP_SLUG = "latest-drop";

export const FUTUREPROOF_URL =
  "https://app.poweredbyfutureproof.com/co2/reskinned";
